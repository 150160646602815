import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ViewFormPage = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    REPORT_LIST: {},
    DESCRIPTION_LIST: [],
    DISEMBARKATION_LIST: [],
    EMBARKATION_LIST: [],
    PIER_SERVICES_LIST: [],
    PORT_SERVICES_LIST: []
  });
  const [lookups, setLookups] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const labelHeaders = {
    // Title: 'Title',
    ShipNameLookupId: 'Ship Name',
    CruiseLineLookupId: 'Cruise Line',
    Port: 'Port',
    Reportpreparedon_x0028_date_x002: 'Report Prepared On',
    ETA_x0028_Pilotstation_x0029_: 'ETA (Pilot Station)',
    Berthnumber: 'Berth Number',
    Previousportofcall: 'Previous Port of Call',
    GRT: 'GRT',
    ETD_x0028_Berth_x0029_: 'ETD (Berth)',
    TypeofCall: 'Type of Call',
    Nextportofcall: 'Next Port of Call',
    Firstcrew_x002f_paxashore: 'First Crew/Pax Ashore',
    Allpaxcrewonboard: 'All Pax/Crew Onboard',
    Shipdeparture: 'Ship Departure',
    Pre_x002d_Arrival_x002f_Post_x00: 'Pre-Arrival/Post-Departure',
    CallIdLookupId: 'Call ID',
    ReportbyLookupId: 'Report By',
    Pilotonboard: 'Pilot Onboard',
    Alllinesfast: 'All Lines Fast',
    Healthauthorityonboard: 'Health Authority Onboard',
    Customsauthorityonboard: 'Customs Authority Onboard',
    Shipcleared: 'Ship Cleared',
    Portclearanceissued: 'Port Clearance Issued',
    Allshipservicescompleted: 'All Ship Services Completed',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found in local storage');
        }

        const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/form/${id}/${type}`, {
          method: 'GET',
          headers: {
            'authorization': token,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch form data');
        }

        const data = await response.json();
        setFormData(data);

        const lookupsResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/lookups?port=${data.REPORT_LIST.Port}`, {
          method: 'GET',
          headers: {
            'authorization': token,
            'Content-Type': 'application/json'
          }
        });

        if (!lookupsResponse.ok) {
          throw new Error('Failed to fetch lookups');
        }

        const lookupsData = await lookupsResponse.json();
        setLookups(lookupsData);

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, type]);

  if (loading) {
    return <div className="flex justify-center items-center h-screen"><div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div></div>;
  }

  if (error) {
    return <div className="text-center text-red-500 dark:text-red-400">{error}</div>;
  }

  const renderTable = (listName) => {
    const columns = {
      DESCRIPTION_LIST: ['DescriptionItemLookupId', 'Passenger', 'Crew', 'Comments'],
      DISEMBARKATION_LIST: ['DisembarkationItemLookupId', 'StartTime', 'FinishTime', 'Count', 'Comments'],
      EMBARKATION_LIST: ['EmbarkationItemLookupId', 'StartTime', 'FinishTime', 'Count', 'Comments'],
      PIER_SERVICES_LIST: ['PierServicesItemLookupId', 'StartTime', 'FinishTime', 'Quantity', 'Comments'],
      PORT_SERVICES_LIST: ['ServicesRenderedLookupId', 'ServiceProvider_x002f_Supplier', 'Quantity', 'Comments']
    };

    return (
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {columns[listName].map((column, index) => index === 0 ? (
                <th key={index} scope="col" className="px-6 py-3">Items</th>
              ) : column == "ServiceProvider_x002f_Supplier" ? (
                <th key={index} scope="col" className="px-6 py-3">Service Provider / Supplier</th>
              ) : column == "StartTime" ? (
                <th key={index} scope="col" className="px-6 py-3">Start Time</th>
              ) : column == "FinishTime" ? (
                <th key={index} scope="col" className="px-6 py-3">Finish Time</th>
              ) : (
                <th key={index} scope="col" className="px-6 py-3">{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {formData[listName].map((item, index) => (
              <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                {columns[listName].map((column, colIndex) => (
                  <td key={colIndex} className="px-6 py-4">
                    {column.includes('LookupId')
                      ? listName.toLowerCase().replace('_list', '') === "port_services"
                        ? lookups['portServices']?.find(lookup => lookup.id === item[column])?.title || item[column]
                        : listName.toLowerCase().replace('_list', '') === "pier_services"
                          ? lookups['pierServices']?.find(lookup => lookup.id === item[column])?.title || item[column]
                          : lookups[listName.toLowerCase().replace('_list', '')]?.find(lookup => lookup.id === item[column])?.title || item[column]
                      : item[column.replace(/ /g, '')]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const getLookupValue = (key, value) => {
    if (key === 'ShipNameLookupId') {
      return lookups.ships?.find(ship => ship.id === value)?.title || value;
    } else if (key === 'CruiseLineLookupId') {
      return lookups.cruiseLines?.find(line => line.id === value)?.title || value;
    } else if (key === 'ReportbyLookupId') {
      return lookups.users?.find(user => user.id === value)?.title || value;
    }
    return value;
  };

  return (
    <div className="container mx-auto p-4">
      <button onClick={() => navigate(-1)} className="bg-blue-500 hover:bg-blue-700 text-white mb-4 font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700">Back</button>
      <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">View {type === 'pre' ? 'Pre-Arrival' : 'Post-Departure'} Form</h1>

      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-gray-900 dark:text-white">Report Information</h2>
        <div className="grid gap-6 mb-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
          {Object.entries(formData.REPORT_LIST).map(([key, value]) => {
            if (labelHeaders[key]) {
              return (
                <div key={key}>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {labelHeaders[key]}
                  </label>
                  <p className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                    {key.includes('date') || key.includes('ETA') || key.includes('ETD')
                      ? value.slice(0, 16)
                      : getLookupValue(key, value)}
                  </p>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>

      {['DESCRIPTION_LIST', 'DISEMBARKATION_LIST', 'EMBARKATION_LIST', 'PIER_SERVICES_LIST', 'PORT_SERVICES_LIST'].map((listName) => (
        <div key={listName} className="mb-6">
          <h2 className="text-2xl font-semibold mb-2 text-gray-900 dark:text-white">{listName.replace('_LIST', '').replace('_', ' ')}</h2>
          {renderTable(listName)}
        </div>
      ))}
    </div>
  );
};

export default ViewFormPage;
