import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import { AuthProvider } from "./components/AuthProvider";
import ProtectedRoute from "./components/ProtectedRoute";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import NewPage from "./pages/NewPage";
import CallsPage from "./pages/CallsPage";
import FormPage from "./pages/FormPage";
import NewFormPage from "./pages/NewFormPage";
import ViewFormPage from "./pages/ViewFormPage";
import EditFormPage from "./pages/EditFormPage";
import ServiceProviderRFP from "./pages/ServiceProviderRFP";
import ShorexForm from "./pages/ShorexForm";
import SharepointForm from "./components/SharepointForm";
import VendorPendingApprovalPage from "./pages/VendorPendingApprovalPage";
import VendorPendingApprovalItemsPage from "./pages/VendorPendingApprovalItemsPage";
import ServiceConfirmationPage from "./pages/ServiceConfirmationPage";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      if (!localStorage.getItem('theme')) {
        setIsDarkMode(e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
      document.body.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      document.body.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <BrowserRouter>
      <AuthProvider>
        <div className={`flex ${isDarkMode ? 'dark' : ''}`}>
          <NavBar isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
          <div className="flex-1 sm:ml-64 mt-16 sm:mt-0 bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
            <Routes>
              <Route path="/" element={<ProtectedRoute><h1>Home</h1></ProtectedRoute>} />
              <Route path="/calendar" element={<ProtectedRoute><CallsPage /></ProtectedRoute>} />
              <Route path="/forms" element={<ProtectedRoute><h1>Forms</h1></ProtectedRoute>} />
              <Route path="/form/:id" element={<ProtectedRoute><FormPage /></ProtectedRoute>} />
              <Route path="/form/:id/:type/new" element={<ProtectedRoute><NewFormPage /></ProtectedRoute>} />
              <Route path="/form/:id/:type/view" element={<ProtectedRoute><ViewFormPage /></ProtectedRoute>} />
              <Route path="/form/:id/:type/edit" element={<ProtectedRoute><EditFormPage /></ProtectedRoute>} />
              <Route path="/shorex/:id" element={<ProtectedRoute><ShorexForm /></ProtectedRoute>} />
              <Route path="/vendor-pending-approval" element={<ProtectedRoute><VendorPendingApprovalPage /></ProtectedRoute>} />
              <Route path="/vendor-approval/:callId/:type" element={<ProtectedRoute><VendorPendingApprovalItemsPage /></ProtectedRoute>} />
              <Route path="/service-confirmation" element={<ProtectedRoute><ServiceConfirmationPage /></ProtectedRoute>} />
              <Route path="/rfp" element={<ServiceProviderRFP />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/new" element={<NewPage />} />
              <Route path="/sharepoint" element={
                <ProtectedRoute>
                  <SharepointForm
                    siteId="cruisesaudi.sharepoint.com,4143b01e-bd0a-4f72-ae18-1334fba60696,3dc58629-6e03-4061-8795-f3f6d1914775"
                    listId="01c32460-a2e3-4902-a43b-88a46ffa1f81"
                    // lockedFields={['ShipName']}
                    // defaultValues={{ Title: "test" }}
                    excludedFields={['Title', 'Description_x0020_Item_x003a__x0']}
                    headerText={{
                      ShipName: "Ship Name",
                      CruiseLine: "Cruise Line",
                      Berthnumber: "Berth Number",
                      Nextportofcall: "Next Port of Call",
                      TypeofCall: "Type of Call",
                      Allpaxcrewonboard: "All Pax Crew Onboard",
                      ETA_x0028_Pilotstation_x0029_: 'ETA (Pilotstation)',
                      Reportpreparedon_x0028_date_x0029_: "Report Prepared On",
                      ETD_x0028_Berth_x0029_: "ETD (Berth)",
                    }}
                    itemId="23"
                  />
                </ProtectedRoute>}
              />
              {/* <Route path="/:type/:id/:token" element={<App />} /> */}
              <Route path="*" element={<h1>404 Not Found</h1>} />
            </Routes>
          </div>
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
