import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ServiceConfirmationPage = () => {
  const [servicesByCall, setServicesByCall] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedServices, setSelectedServices] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found in local storage");
        }

        const response = await fetch(
          `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/service-confirmations`,
          {
            method: "GET",
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch services");
        }

        const data = await response.json();
        console.log(data);
        setServicesByCall(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found in local storage");
      }

      const decisions = Object.entries(selectedServices).map(
        ([serviceId, data]) => ({
          serviceId,
          status: data.status,
          comment: data.comment,
        })
      );

      const response = await fetch(
        `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/submitServiceConfirmation`,
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ decisions }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit confirmations");
      }

      navigate(-1);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 dark:text-red-400">{error}</div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <header className="mb-8">
        <button
          onClick={() => navigate(-1)}
          className="bg-blue-500 hover:bg-blue-700 text-white mb-4 font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700"
        >
          Back
        </button>
        <h1 className="text-3xl font-bold mb-4">Service Confirmations</h1>
      </header>

      {Object.entries(servicesByCall).map(([callTitle, services]) => (
        <div key={callTitle} className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200 border-b-2 border-gray-200 dark:border-gray-700 pb-2 flex items-center">
            <span className="bg-blue-100 dark:bg-blue-900 rounded-lg px-3 py-1 mr-2">
              {callTitle}
            </span>
            <div className="flex gap-2">
              <button
                className="bg-green-500 hover:bg-green-700 text-white px-3 py-1 rounded-lg text-sm font-medium"
                onClick={() => {
                  const updatedServices = {};
                  services.forEach((service) => {
                    updatedServices[service.id] = {
                      ...selectedServices[service.id],
                      status: "Confirmed",
                    };
                  });
                  setSelectedServices((prev) => ({
                    ...prev,
                    ...updatedServices,
                  }));
                }}
              >
                Confirm All
              </button>
              <button
                className="bg-red-500 hover:bg-red-700 text-white px-3 py-1 rounded-lg text-sm font-medium"
                onClick={() => {
                  const updatedServices = {};
                  services.forEach((service) => {
                    updatedServices[service.id] = {
                      ...selectedServices[service.id],
                      status: "Rejected",
                    };
                  });
                  setSelectedServices((prev) => ({
                    ...prev,
                    ...updatedServices,
                  }));
                }}
              >
                Reject All
              </button>
            </div>
          </h2>
          <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6">
                    Status
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Comment
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Service Type
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Type
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Start Time
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Finish Time
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Total Hours
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody>
                {services.map((service) => (
                  <tr
                    key={service.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td className="py-4 px-6">
                      <div className="flex flex-col gap-2">
                        <button
                          className={`px-3 py-1 rounded-lg font-medium text-sm ${selectedServices[service.id]?.status === "Confirmed"
                            ? "bg-green-500 text-white"
                            : "bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300"
                            }`}
                          onClick={() =>
                            setSelectedServices((prev) => ({
                              ...prev,
                              [service.id]: {
                                ...prev[service.id],
                                status:
                                  prev[service.id]?.status === "Confirmed"
                                    ? ""
                                    : "Confirmed",
                              },
                            }))
                          }
                        >
                          Confirm
                        </button>
                        <button
                          className={`px-3 py-1 rounded-lg font-medium text-sm ${selectedServices[service.id]?.status === "Rejected"
                            ? "bg-red-500 text-white"
                            : "bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300"
                            }`}
                          onClick={() =>
                            setSelectedServices((prev) => ({
                              ...prev,
                              [service.id]: {
                                ...prev[service.id],
                                status:
                                  prev[service.id]?.status === "Rejected"
                                    ? ""
                                    : "Rejected",
                              },
                            }))
                          }
                        >
                          Reject
                        </button>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <input
                        type="text"
                        className={`bg-gray-50 border ${selectedServices[service.id]?.status === "Rejected" &&
                          (selectedServices[service.id]?.comment ===
                            undefined ||
                            selectedServices[service.id]?.comment === "")
                          ? "border-red-500"
                          : "border-gray-300 dark:border-gray-600"
                          } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white`}
                        value={selectedServices[service.id]?.comment || ""}
                        onChange={(e) =>
                          setSelectedServices((prev) => ({
                            ...prev,
                            [service.id]: {
                              ...prev[service.id],
                              comment: e.target.value,
                            },
                          }))
                        }
                        required={
                          selectedServices[service.id]?.status === "Rejected"
                        }
                        placeholder={
                          selectedServices[service.id]?.status === "Rejected"
                            ? "Required for rejection"
                            : "Optional comment"
                        }
                      />
                    </td>
                    <td className="py-4 px-6">{service.ServiceType}</td>
                    <td className="py-4 px-6">{service.type}</td>
                    <td className="py-4 px-6">{service.StartTime}</td>
                    <td className="py-4 px-6">{service.FinishTime}</td>
                    <td className="py-4 px-6">{service.TotalHours}</td>
                    <td className="py-4 px-6">{service.Quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}

      <div className="mt-4 flex gap-4 justify-start">
        <button
          onClick={() => setShowConfirmation(true)}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
          disabled={
            Object.keys(selectedServices).length === 0 ||
            Object.values(selectedServices).some(
              (service) =>
                service.status === "Rejected" &&
                (!service.comment || service.comment.trim() === "")
            )
          }
        >
          Submit Confirmations
        </button>
        <button
          onClick={() => setSelectedServices({})}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          Clear All
        </button>
      </div>

      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-bold mb-4 dark:text-white">
              Confirm Services
            </h3>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              Are you sure you want to submit the following confirmations?
              <br />
              <br />
              Confirmed:{" "}
              {
                Object.values(selectedServices).filter(
                  (v) => v.status === "Confirmed"
                ).length
              }{" "}
              services
              <br />
              Rejected:{" "}
              {
                Object.values(selectedServices).filter(
                  (v) => v.status === "Rejected"
                ).length
              }{" "}
              services
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowConfirmation(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setShowConfirmation(false);
                  handleSubmit();
                }}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceConfirmationPage;
