import { useState, useEffect, useRef } from 'react';

const SearchDropdown = ({ options, onSelect, placeholder, required = false, disabled = false, defaultValue }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(defaultValue || '');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);

    useEffect(() => {
        if (defaultValue) {
            setSearchTerm(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        setFilteredOptions(
            options.filter((option) =>
                option.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setSelectedIndex(-1);
    }, [searchTerm, options]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        setIsOpen(true);
    };

    const handleOptionClick = (option) => {
        onSelect(option);
        setSearchTerm(option);
        setIsOpen(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedIndex((prevIndex) =>
                prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
            );
        } else if (e.key === 'Enter') {
            if (selectedIndex !== -1) {
                onSelect(filteredOptions[selectedIndex]);
                setSearchTerm(filteredOptions[selectedIndex]);
                setIsOpen(false);
            } else {
                if (filteredOptions.length > 0) {
                    onSelect(filteredOptions[0]);
                    setSearchTerm(filteredOptions[0]);
                } else {
                    setIsOpen(false);
                }
            }
        };
    }

    return (
        <div className="relative" ref={dropdownRef}>
            <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleInputChange}
                onClick={() => setIsOpen(true)}
                onFocus={() => setIsOpen(true)}
                onBlur={() => setTimeout(() => setIsOpen(false), 200)}
                onKeyDown={handleKeyDown}
                required={required}
                disabled={disabled}
            />
            {isOpen && (
                <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto dark:bg-gray-800 dark:border-gray-700">
                    {filteredOptions.map((option, index) => (
                        <li
                            key={index}
                            className={`px-4 py-2 cursor-pointer ${index === selectedIndex
                                ? 'bg-blue-500 text-white dark:bg-blue-600'
                                : 'hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-400'
                                }`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchDropdown;
