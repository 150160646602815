import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchDropdown from './SearchDropdown';

const SharepointForm = ({ siteId, listId, headerText, excludedFields, lockedFields, defaultValues = {}, itemId }) => {
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState([]);
    const [formData, setFormData] = useState(defaultValues);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const fetchListItem = async (selectedFields) => {
            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/listItem/${siteId}/${listId}/${itemId}/${selectedFields.join(',')}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            console.log('response', response);
            if (!response.ok) {
                throw new Error('Failed to fetch list item');
            }
            const result = await response.json();
            console.log('result', result);
            setFormData(prevData => ({
                ...prevData,
                ...result
            }));
            setLoading(false);
        };
        const fetchFormFields = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/listColumns/${siteId}/${listId}`, {
                    headers: {
                        // 'Authorization': localStorage.getItem('token'),
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch form fields');
                }
                let data = await response.json();
                data = excludedFields ? data.filter(field => !excludedFields.includes(field.name)) : data;
                console.log('data', data);
                setFormFields(data);
                setFormData(prevData => ({
                    ...prevData,
                    ...data.reduce((acc, field) => ({
                        ...acc,
                        [field.name]: prevData[field.name] !== undefined ? prevData[field.name] : ''
                    }), {})
                }));
                console.log('formData', formData);
                if (itemId) {
                    fetchListItem(data.map(field => field.name));
                }
                else {
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                setError(error.message);
            }
        };

        fetchFormFields();
    }, [siteId, listId, itemId]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleDropdownChange = (name, value) => {
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        console.log('formData', formData);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }
            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                body: JSON.stringify({ formData, siteId, listId })
            });
            if (!response.ok) {
                throw new Error('Failed to submit form');
            }
            const result = await response.json();
            console.log('Form submitted successfully:', result);
            setSuccess(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            setError(error.message);
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen"><div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div></div>;
    }

    const renderField = (field) => {
        switch (field.type.name) {
            case 'text':
                return field.type.value.allowMultipleLines ? (
                    <textarea
                        disabled={(lockedFields && lockedFields.includes(field.name)) ?? false}
                        placeholder={`${field.displayName} (max ${field.type.value.maxLength} characters)`}
                        name={field.name}
                        value={formData[field.name]}
                        required={field.required}
                        onChange={handleInputChange}
                        rows={field.type.value.linesForEditing || 3}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                ) : (
                    <input
                        disabled={(lockedFields && lockedFields.includes(field.name)) ?? false}
                        placeholder={`Enter ${field.displayName} here...`}
                        type="text"
                        name={field.name}
                        value={formData[field.name]}
                        required={field.required}
                        onChange={handleInputChange}
                        maxLength={field.type.value.maxLength}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                );
            case 'choice':
                return (
                    <SearchDropdown
                        disabled={(lockedFields && lockedFields.includes(field.name)) ?? false}
                        options={field.type.value.choices}
                        onSelect={(value) => handleDropdownChange(field.name, value)}
                        placeholder={`Select ${field.displayName}...`}
                        required={field.required}
                        defaultValue={formData[field.name]}
                    />
                );
            case 'dateTime':
                return (
                    <input
                        disabled={(lockedFields && lockedFields.includes(field.name)) ?? false}
                        type="datetime-local"
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleInputChange}
                        required={field.required}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                );
            case 'personOrGroup':
                return (
                    <input
                        disabled={(lockedFields && lockedFields.includes(field.name)) ?? false}
                        type="text"
                        name={field.name}
                        value={formData[field.name]}
                        required={field.required}
                        onChange={handleInputChange}
                        placeholder="Enter user name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                );
            case 'number':
            case 'currency':
                return (
                    <input
                        disabled={(lockedFields && lockedFields.includes(field.name)) ?? false}
                        placeholder={`Enter ${field.displayName} here...`}
                        type="number"
                        name={field.name}
                        value={formData[field.name]}
                        required={field.required}
                        onChange={handleInputChange}
                        min={field.type.value.minimum}
                        max={field.type.value.maximum}
                        step={field.type.name === 'currency' ? '0.01' : '1'}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                );
            case 'boolean':
                return (
                    <input
                        disabled={(lockedFields && lockedFields.includes(field.name)) ?? false}
                        type="checkbox"
                        name={field.name}
                        checked={formData[field.name]}
                        required={field.required}
                        onChange={handleInputChange}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                );
            case 'lookup':
                return (
                    <SearchDropdown
                        disabled={(lockedFields && lockedFields.includes(field.name)) ?? false}
                        options={field.type.value.choices.map(choice => choice.title)}
                        onSelect={(value) => handleDropdownChange(field.name, field.type.value.choices.find(choice => choice.title === value)?.id)}
                        placeholder={`Select ${field.displayName}...`}
                        required={field.required}
                        defaultValue={field.type.value.choices.find(choice => choice.id === formData[field.name])?.title}
                    />
                );
            default:
                return (
                    <input
                        disabled={(lockedFields && lockedFields.includes(field.name)) ?? false}
                        placeholder={`Enter ${field.displayName} here...`}
                        type="text"
                        name={field.name}
                        value={formData[field.name]}
                        required={field.required}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                );
        }
    };

    return (
        <div className="container mx-auto p-4">
            {submitting && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            )}
            {success && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center">
                        <svg className="w-16 h-16 text-green-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Success!</h2>
                        <p className="text-gray-700 dark:text-gray-300 mb-4">Your form has been submitted successfully.</p>
                        <button
                            onClick={() => {
                                setSuccess(false);
                                // navigate(-1);
                            }}
                            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded dark:bg-green-600 dark:hover:bg-green-700"
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
            {error && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center">
                        <svg className="w-16 h-16 text-red-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Error</h2>
                        <p className="text-gray-700 dark:text-gray-300 mb-4">{error}</p>
                        <button
                            onClick={() => setError(null)}
                            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded dark:bg-red-600 dark:hover:bg-red-700"
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    {formFields.map((field) => (
                        <div key={field.name}>
                            <label htmlFor={field.name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                {headerText && headerText.hasOwnProperty(field.name) ? headerText[field.name] : field.displayName}
                            </label>
                            {renderField(field)}
                        </div>
                    ))}
                </div>
                <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default SharepointForm;
