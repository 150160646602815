import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  UserPlusIcon,
  UserIcon,
  CalendarIcon,
  DocumentTextIcon,
  Bars3Icon,
  SunIcon,
  MoonIcon,
  ArrowRightOnRectangleIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "./AuthProvider"; // Import the useAuth hook

const NavBar = ({ isDarkMode, setIsDarkMode }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState([]);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useAuth(); // Access the user from the AuthProvider
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setUserRoles(JSON.parse(localStorage.getItem("user"))?.rules || []);

    console.log("userRoles", userRoles);

    const baseNavigation = [
      {
        name: "Calls Calendar",
        href: "/calendar",
        icon: CalendarIcon,
        current: false,
        rules: ["Admin", "PAD", "GAC"],
      },
      {
        name: "Approvals",
        href: "/vendor-pending-approval",
        icon: DocumentTextIcon,
        current: false,
        rules: ["Admin", "GAC", "SGS", "SAFE"],
      },
      {
        name: "Service Confirmations",
        href: "/service-confirmation",
        icon: DocumentTextIcon,
        current: false,
        rules: ["Admin", "GAC", "SGS", "SAFE"],
      },
      {
        name: "Shore Excursions Pricing",
        href: "/rfp",
        icon: CurrencyDollarIcon,
        current: false,
        rules: ["Admin", "Pricing"],
      },
    ];

    const filteredNavigation = baseNavigation.filter(
      (item) =>
        item.rules && userRoles.some((role) => item.rules.includes(role))
    );

    if (!token) {
      filteredNavigation.push({
        name: "Sign In",
        href: "/signin",
        icon: UserIcon,
        current: false,
      });
    }

    setNavigation(filteredNavigation);
  }, [user]); // Re-run the effect whenever the user changes

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    if (isDarkMode) {
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.add("dark");
    }
  };

  const handleSignOut = () => {
    localStorage.clear();
    setUserRoles([]);
    navigate("/signin");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        isSidebarOpen
      ) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <>
      <div
        className={`fixed top-4 left-4 z-50 flex space-x-2 sm:hidden ${isSidebarOpen ? "hidden" : ""
          }`}
      >
        <button
          onClick={toggleSidebar}
          type="button"
          className="inline-flex items-center p-2 text-sm text-gray-500 bg-white rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="w-6 h-6" />
        </button>
        <button
          onClick={toggleDarkMode}
          className="inline-flex items-center p-2 text-sm text-gray-500 bg-white rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          {isDarkMode ? (
            <SunIcon className="w-6 h-6" />
          ) : (
            <MoonIcon className="w-6 h-6" />
          )}
        </button>
      </div>

      <aside
        ref={sidebarRef}
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <div className="flex items-center justify-between mb-5">
            <Link
              to="/calendar"
              className="p-2 bg-white rounded-lg dark:bg-white"
            >
              <img src="/logo2.png" alt="Logo" className="h-12 w-auto" />
            </Link>
            <button
              onClick={toggleDarkMode}
              className="p-2 text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
              {isDarkMode ? (
                <SunIcon className="w-5 h-5" />
              ) : (
                <MoonIcon className="w-5 h-5" />
              )}
            </button>
          </div>
          <ul className="space-y-2 font-medium">
            {navigation.map((item) =>
              (userRoles &&
                item.rules &&
                userRoles.some((role) => item.rules.includes(role))) ||
                !item.rules ? (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-white"
                        : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700",
                      "flex items-center p-2 rounded-lg group"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-900 dark:text-white"
                          : "text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white",
                        "w-5 h-5 transition duration-75"
                      )}
                      aria-hidden="true"
                    />
                    <span className="ms-3">{item.name}</span>
                  </Link>
                </li>
              ) : null
            )}
            {user && (
              <li>
                <button
                  onClick={handleSignOut}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group w-full"
                >
                  <ArrowRightOnRectangleIcon
                    className="w-5 h-5 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white transition duration-75"
                    aria-hidden="true"
                  />
                  <span className="ms-3">Sign Out</span>
                </button>
              </li>
            )}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default NavBar;
