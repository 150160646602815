import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const EditFormPage = () => {
    const { id, type } = useParams();
    const navigate = useNavigate();
    const [callInfo, setCallInfo] = useState(null);
    const [lookups, setLookups] = useState({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [formData, setFormData] = useState({
        REPORT_LIST: {
            Title: '',
            ShipNameLookupId: '',
            CruiseLineLookupId: '',
            Port: '',
            ReportbyLookupId: '',
            Reportpreparedon_x0028_date_x002: '',
            GRT: '',
            ETA_x0028_Pilotstation_x0029_: '',
            ETD_x0028_Berth_x0029_: '',
            Berthnumber: '',
            TypeofCall: '',
            Previousportofcall: '',
            Nextportofcall: '',
            Pilotonboard: '',
            Alllinesfast: '',
            Healthauthorityonboard: '',
            Customsauthorityonboard: '',
            Shipcleared: '',
            Firstcrew_x002f_paxashore: '',
            Allshipservicescompleted: '',
            Allpaxcrewonboard: '',
            Portclearanceissued: '',
            Shipdeparture: '',
            Pre_x002d_Arrival_x002f_Post_x00: type === 'pre' ? 'Pre-Arrival' : 'Post Departure',
            CallIdLookupId: id,
        },
        DESCRIPTION_LIST: [],
        DISEMBARKATION_LIST: [],
        EMBARKATION_LIST: [],
        PIER_SERVICES_LIST: [],
        PORT_SERVICES_LIST: []
    });
    const [labelHeaders, setLabelHeaders] = useState({
        ShipNameLookupId: 'Ship Name',
        CruiseLineLookupId: 'Cruise Line',
        Port: 'Port',
        Reportpreparedon_x0028_date_x002: 'Report Prepared On',
        ETA_x0028_Pilotstation_x0029_: 'ETA (Pilot Station)',
        Berthnumber: 'Berth Number',
        Previousportofcall: 'Previous Port of Call',
        GRT: 'GRT',
        ETD_x0028_Berth_x0029_: 'ETD (Berth)',
        TypeofCall: 'Type of Call',
        Nextportofcall: 'Next Port of Call',
        Firstcrew_x002f_paxashore: 'First Crew/Pax Ashore',
        Allpaxcrewonboard: 'All Pax/Crew Onboard',
        Shipdeparture: 'Ship Departure',
        Pre_x002d_Arrival_x002f_Post_x00: 'Pre-Arrival/Post-Departure',
        CallIdLookupId: 'Call ID',
        ReportbyLookupId: 'Report By',
        Pilotonboard: 'Pilot Onboard',
        Alllinesfast: 'All Lines Fast',
        Healthauthorityonboard: 'Health Authority Onboard',
        Customsauthorityonboard: 'Customs Authority Onboard',
        Shipcleared: 'Ship Cleared',
        Portclearanceissued: 'Port Clearance Issued',
        Allshipservicescompleted: 'All Ship Services Completed',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No token found in local storage');
                }

                const formResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/form/${id}/${type}`, {
                    method: 'GET',
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json'
                    }
                });

                if (!formResponse.ok) {
                    throw new Error('Failed to fetch form data');
                }

                const formData = await formResponse.json();
                setFormData({
                    ...formData,
                    REPORT_LIST: {
                        ...formData.REPORT_LIST,
                        ETA_x0028_Pilotstation_x0029_: formData.REPORT_LIST.ETA_x0028_Pilotstation_x0029_.slice(0, 16),
                        ETD_x0028_Berth_x0029_: formData.REPORT_LIST.ETD_x0028_Berth_x0029_.slice(0, 16),
                        Reportpreparedon_x0028_date_x002: formData.REPORT_LIST.Reportpreparedon_x0028_date_x002.slice(0, 16)
                    }

                });

                const lookupsResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/lookups?port=${formData.REPORT_LIST.Port}`, {
                    method: 'GET',
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json'
                    }
                });

                if (!lookupsResponse.ok) {
                    throw new Error('Failed to fetch lookups');
                }

                const lookupsData = await lookupsResponse.json();
                setLookups(lookupsData);

                const fetchLookupValues = async (listId, itemId) => {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/lookup/${listId}/${itemId}`, {
                            headers: {
                                'authorization': token,
                                'Content-Type': 'application/json'
                            }
                        });
                        if (!response.ok) {
                            throw new Error('Failed to fetch lookup value');
                        }
                        return await response.json();
                    } catch (error) {
                        console.error('Error fetching lookup value:', error);
                        return null;
                    }
                };

                const [shipLookup, cruiseLineLookup, reportByLookup] = await Promise.all([
                    fetchLookupValues(process.env.REACT_APP_SHIP_LOOKUP_LIST_ID, formData.REPORT_LIST.ShipNameLookupId),
                    fetchLookupValues(process.env.REACT_APP_CRUISE_LOOKUP_LIST_ID, formData.REPORT_LIST.CruiseLineLookupId),
                    fetchLookupValues(process.env.REACT_APP_USERS_LIST_ID, formData.REPORT_LIST.ReportbyLookupId)
                ]);
                setFormData(prevState => ({
                    ...prevState,
                    REPORT_LIST: {
                        ...prevState.REPORT_LIST,
                        ShipNameLookupId: shipLookup ? shipLookup.ShipName : prevState.REPORT_LIST.ShipNameLookupId,
                        CruiseLineLookupId: cruiseLineLookup ? cruiseLineLookup.Title : prevState.REPORT_LIST.CruiseLineLookupId,
                        ReportbyLookupId: reportByLookup ? reportByLookup.Name : prevState.REPORT_LIST.ReportbyLookupId
                    }
                }));
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [id, type]);

    const handleReportChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            REPORT_LIST: { ...prevState.REPORT_LIST, [name]: value }
        }));
    };

    const handleItemChange = (listName, id, field, value) => {
        setFormData(prevState => ({
            ...prevState,
            [listName]: prevState[listName].map(item =>
                item.id === id ? { ...item, [field]: value } : item
            )
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowConfirmation(false);
        setSubmitting(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }

            const formattedData = {
                ...formData,
                REPORT_LIST: {
                    ...formData.REPORT_LIST,
                    ReportbyLookupId: JSON.parse(localStorage.getItem('user')).id,
                    ETA_x0028_Pilotstation_x0029_: formData.REPORT_LIST.ETA_x0028_Pilotstation_x0029_ ? new Date(formData.REPORT_LIST.ETA_x0028_Pilotstation_x0029_).toISOString() : null,
                    ETD_x0028_Berth_x0029_: formData.REPORT_LIST.ETD_x0028_Berth_x0029_ ? new Date(formData.REPORT_LIST.ETD_x0028_Berth_x0029_).toISOString() : null,
                    Reportpreparedon_x0028_date_x002: formData.REPORT_LIST.Reportpreparedon_x0028_date_x002 ? new Date(formData.REPORT_LIST.Reportpreparedon_x0028_date_x002).toISOString() : null
                }
            };

            const newFormData = {
                ...formData,
                REPORT_LIST: {
                    id: formData.REPORT_LIST.id,
                    Berthnumber: formData.REPORT_LIST.Berthnumber,
                    GRT: formData.REPORT_LIST.GRT,
                    Firstcrew_x002f_paxashore: formData.REPORT_LIST.Firstcrew_x002f_paxashore,
                    Allpaxcrewonboard: formData.REPORT_LIST.Allpaxcrewonboard,
                    Shipdeparture: formData.REPORT_LIST.Shipdeparture,
                    Pilotonboard: formData.REPORT_LIST.Pilotonboard,
                    Alllinesfast: formData.REPORT_LIST.Alllinesfast,
                    Healthauthorityonboard: formData.REPORT_LIST.Healthauthorityonboard,
                    Customsauthorityonboard: formData.REPORT_LIST.Customsauthorityonboard,
                    Shipcleared: formData.REPORT_LIST.Shipcleared,
                    Portclearanceissued: formData.REPORT_LIST.Portclearanceissued,
                    Allshipservicescompleted: formData.REPORT_LIST.Allshipservicescompleted,
                    Status: formData.REPORT_LIST.Status,
                    RejectionReason: formData.REPORT_LIST.RejectionReason
                },
                DESCRIPTION_LIST: formData.DESCRIPTION_LIST.map(item => Object.fromEntries(Object.entries(item).filter(([key]) => ['id', 'DescriptionItemLookupId', 'Passenger', 'Crew', 'Comments', 'Status', 'RejectionReason'].includes(key)))),
                DISEMBARKATION_LIST: formData.DISEMBARKATION_LIST.map(item => Object.fromEntries(Object.entries(item).filter(([key]) => ['id', 'DisembarkationItemLookupId', 'StartTime', 'FinishTime', 'Count', 'Comments', 'Status', 'RejectionReason'].includes(key)))),
                EMBARKATION_LIST: formData.EMBARKATION_LIST.map(item => Object.fromEntries(Object.entries(item).filter(([key]) => ['id', 'EmbarkationItemLookupId', 'StartTime', 'FinishTime', 'Count', 'Comments', 'Status', 'RejectionReason'].includes(key)))),
                PIER_SERVICES_LIST: formData.PIER_SERVICES_LIST.map(item => Object.fromEntries(Object.entries(item).filter(([key]) => ['id', 'PierServicesItemLookupId', 'StartTime', 'FinishTime', 'Quantity', 'Comments', 'Status', 'RejectionReason'].includes(key)))),
                PORT_SERVICES_LIST: formData.PORT_SERVICES_LIST.map(item => Object.fromEntries(Object.entries(item).filter(([key]) => ['id', 'ServicesRenderedLookupId', 'ServiceProvider_x002f_Supplier', 'Quantity', 'Comments', 'Status', 'RejectionReason'].includes(key)))),
            };

            console.log(newFormData);

            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/updateForm`, {
                method: 'PUT',
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newFormData),
            });
            if (response.ok) {
                setSubmitting(false);
                setShowSuccess(true);
            } else {
                throw new Error('Form update failed');
            }
        } catch (error) {
            console.error('Error updating form:', error);
            alert('Error updating form');
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen"><div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div></div>;
    }

    if (error) {
        return <div className="text-center text-red-500 dark:text-red-400">{error}</div>;
    }

    const columns = {
        DESCRIPTION_LIST: ['DescriptionItemLookupId', 'Passenger', 'Crew', 'Comments'],
        DISEMBARKATION_LIST: ['DisembarkationItemLookupId', 'StartTime', 'FinishTime', 'Count', 'Comments'],
        EMBARKATION_LIST: ['EmbarkationItemLookupId', 'StartTime', 'FinishTime', 'Count', 'Comments'],
        PIER_SERVICES_LIST: ['PierServicesItemLookupId', 'StartTime', 'FinishTime', 'Quantity', 'Comments', 'Status', 'RejectionReason'],
        PORT_SERVICES_LIST: ['ServicesRenderedLookupId', 'ServiceProvider_x002f_Supplier', 'Quantity', 'Comments', 'Status', 'RejectionReason']
    };

    const renderTable = (listName) => {
        return (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            {columns[listName].map((column, index) => index === 0 ? (
                                <th key={index} scope="col" className="px-6 py-3">Items</th>
                            ) : column == "ServiceProvider_x002f_Supplier" ? (
                                <th key={index} scope="col" className="px-6 py-3">Service Provider / Supplier</th>
                            ) : column == "StartTime" ? (
                                <th key={index} scope="col" className="px-6 py-3">Start Time</th>
                            ) : column == "FinishTime" ? (
                                <th key={index} scope="col" className="px-6 py-3">Finish Time</th>
                            ) : column == "RejectionReason" ? (
                                <th key={index} scope="col" className="px-6 py-3">Rejection Reason</th>
                            ) : (
                                <th key={index} scope="col" className="px-6 py-3">{column}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {formData[listName].map((item, index) => (
                            <tr key={item.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                {columns[listName].map((column, colIndex) => (
                                    <td key={colIndex} className="px-6 py-4">
                                        {column === 'Status' ? (
                                            <span className={`px-2 py-1 rounded-full text-xs font-medium ${item[column] === 'Approved' ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100' :
                                                item[column] === 'Rejected' ? 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100' :
                                                    'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100'
                                                }`}>
                                                {item[column]}
                                            </span>
                                        ) : column === 'RejectionReason' ? (
                                            item['Status'] === 'Rejected' ? (
                                                <input
                                                    type="text"
                                                    value={item[column] || ''}
                                                    onChange={(e) => handleItemChange(listName, item.id, column, e.target.value)}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    disabled
                                                />
                                            ) : null
                                        ) : column.includes('LookupId') ? (
                                            listName.toLowerCase().replace('_list', '') === "port_services"
                                                ? lookups['portServices']?.find(lookup => lookup.id === item[column])?.title || item[column]
                                                : listName.toLowerCase().replace('_list', '') === "pier_services"
                                                    ? lookups['pierServices']?.find(lookup => lookup.id === item[column])?.title || item[column]
                                                    : lookups[listName.toLowerCase().replace('_list', '')]?.find(lookup => lookup.id === item[column])?.title || item[column]
                                        ) : (
                                            <input
                                                type={column.includes('Time') ? 'time' : column === 'Count' || column === 'Quantity' || column === 'Passenger' || column === 'Crew' ? 'number' : 'text'}
                                                value={item[column] || ''}
                                                onChange={(e) => handleItemChange(listName, item.id, column, e.target.value)}
                                                className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${item['Status'] === 'Rejected'
                                                    ? 'border-red-500 dark:border-red-500'
                                                    : 'border-gray-300 dark:border-gray-600'
                                                    }`}
                                                disabled={item['Status'] !== 'Rejected'}
                                            />
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const getLookupValue = (key, value) => {
        if (key === 'ShipNameLookupId') {
            return lookups.ships?.find(ship => ship.id === value)?.title || value;
        } else if (key === 'CruiseLineLookupId') {
            return lookups.cruiseLines?.find(line => line.id === value)?.title || value;
        } else if (key === 'ReportbyLookupId') {
            return lookups.users?.find(user => user.id === value)?.title || value;
        }
        return value;
    };

    return (
        <div className="container mx-auto p-4 relative">
            {submitting && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            )}
            {showSuccess && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center">
                        <svg className="w-16 h-16 text-green-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Success!</h2>
                        <p className="text-gray-700 dark:text-gray-300 mb-4">Your form has been updated successfully.</p>
                        <button
                            onClick={() => {
                                setShowSuccess(false);
                                navigate(-1);
                            }}
                            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded dark:bg-green-600 dark:hover:bg-green-700"
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
            {showConfirmation && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center">
                        <svg className="w-16 h-16 text-yellow-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                        </svg>
                        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Confirm Submission</h2>
                        <p className="text-gray-700 dark:text-gray-300 mb-4">Are you sure you want to submit this form?</p>
                        <div className="flex space-x-4">
                            <button
                                onClick={handleSubmit}
                                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded dark:bg-green-600 dark:hover:bg-green-700"
                            >
                                Confirm
                            </button>
                            <button
                                onClick={() => setShowConfirmation(false)}
                                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded dark:bg-red-600 dark:hover:bg-red-700"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className={submitting || showSuccess ? 'pointer-events-none' : ''}>
                <button onClick={() => navigate(-1)} className="bg-blue-500 hover:bg-blue-700 text-white mb-1 font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700">Back</button>
                <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">Edit {type === 'pre' ? 'Pre-Arrival' : 'Post-Departure'} Form</h1>
                <form onSubmit={(e) => { e.preventDefault(); setShowConfirmation(true) }}>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2 text-gray-900 dark:text-white">Report Information</h2>
                        <span className={`px-2 py-1 rounded-full text-xs font-medium ${formData.REPORT_LIST.Status === 'Approved' ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100' :
                            formData.REPORT_LIST.Status === 'Rejected' ? 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100' :
                                'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100'
                            }`}>
                            {formData.REPORT_LIST.Status}
                        </span>
                        {formData.REPORT_LIST.Status === 'Rejected' && formData.REPORT_LIST.RejectionReason && (
                            <p className="mt-2 text-sm text-red-600 dark:text-red-400">
                                Rejection Reason: {formData.REPORT_LIST.RejectionReason}
                            </p>
                        )}
                        <div className="grid gap-6 mb-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
                            {Object.keys(formData.REPORT_LIST).map((field) => {
                                if (!labelHeaders[field]) return null;
                                let isDisabled = formData.REPORT_LIST.Status === 'Pending' || formData.REPORT_LIST.Status === 'Approved' || ['Title', 'ShipNameLookupId', 'CruiseLineLookupId', 'Port', 'Previousportofcall', 'TypeofCall', 'Nextportofcall', 'ReportbyLookupId', 'Reportpreparedon_x0028_date_x002', 'ETA_x0028_Pilotstation_x0029_', 'ETD_x0028_Berth_x0029_', 'CallIdLookupId', 'Pre_x002d_Arrival_x002f_Post_x00'].includes(field);
                                return (
                                    <div key={field}>
                                        <label htmlFor={field} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            {labelHeaders[field]}
                                        </label>
                                        <input
                                            type={
                                                field.includes('date') || field.includes('ETA') || field.includes('ETD')
                                                    ? "datetime-local"
                                                    : ['Pilotonboard', 'Alllinesfast', 'Healthauthorityonboard', 'Customsauthorityonboard', 'Shipcleared', 'Firstcrew_x002f_paxashore', 'Allshipservicescompleted', 'Allpaxcrewonboard', 'Portclearanceissued', 'Shipdeparture'].includes(field)
                                                        ? "datetime-local"
                                                        : "text"
                                            }
                                            id={field}
                                            name={field}
                                            value={formData.REPORT_LIST[field]}
                                            onChange={handleReportChange}
                                            onFocus={(e) => {
                                                if (e.target.type === "datetime-local") {
                                                    setTimeout(() => {
                                                        e.target.showPicker();
                                                    }, 0);
                                                }
                                            }}
                                            className={`bg-gray-50 border ${!isDisabled ? 'border-red-500 dark:border-red-400' : 'border-gray-300 dark:border-gray-600'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                            required
                                            disabled={isDisabled}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <hr className="my-6 border-t border-gray-300 dark:border-gray-600" />
                    {['DESCRIPTION_LIST', 'DISEMBARKATION_LIST', 'EMBARKATION_LIST', 'PIER_SERVICES_LIST', 'PORT_SERVICES_LIST'].map((listName) => (
                        <div key={listName} className="mb-6">
                            <h2 className="text-2xl font-semibold mb-2 text-gray-900 dark:text-white">{listName.replace('_LIST', '').replace('_', ' ')}</h2>
                            {renderTable(listName)}
                            <hr className="my-6 border-t border-gray-300 dark:border-gray-600" />
                        </div>
                    ))}

                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default EditFormPage;