import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const VendorPendingApprovalPage = () => {
  const [pendingCalls, setPendingCalls] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPendingCalls = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found in local storage");
        }

        const response = await fetch(
          `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getPendingCallCodes`,
          {
            method: "GET",
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch pending calls");
        }

        const data = await response.json();
        setPendingCalls(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPendingCalls();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 dark:text-red-400">{error}</div>
    );
  }

  const renderTable = (type, calls) => {
    return (
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                Call Details
              </th>
              <th scope="col" className="py-3 px-6">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {calls && calls.length > 0 ? (
              calls.map((call, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="py-4 px-6">{call.call}</td>
                  <td className="py-4 px-6">
                    <Link
                      to={`/vendor-approval/${encodeURIComponent(call.id)}/${
                        type.toLowerCase() === "pre-arrival" ? "pre" : "post"
                      }`}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs dark:bg-gray-600 dark:hover:bg-gray-700"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td colSpan="2" className="py-4 px-6 text-center">
                  No pending {type} calls.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="container mx-auto p-4">
      <header className="mb-8">
        <h1 className="text-3xl font-bold mb-4">Pending Approval Calls</h1>
      </header>

      <section>
        <h2 className="text-2xl font-bold mb-4">Pre-Arrival Forms</h2>
        {renderTable("Pre-Arrival", pendingCalls?.["Pre-Arrival"])}
      </section>

      <section className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Post-Departure Forms</h2>
        {renderTable("Post Departure", pendingCalls?.["Post Departure"])}
      </section>
    </div>
  );
};

export default VendorPendingApprovalPage;
