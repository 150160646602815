import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

let structure = [
    { systemName: "TourCode", name: "Tour Code", type: "dropdown", options: [] },
    { systemName: "TourName", name: "Tour Name", type: "text", readOnly: true },
    { systemName: "TourOps", name: "Tour Operator", type: "dropdown", options: [] },
    { systemName: "Bus", name: "Bus No.", type: "text" },
    { systemName: "DepartureTime", name: "Departure Time", type: "datetime-local" },
    { systemName: "ReturnTime", name: "Return Time", type: "datetime-local" },
    { systemName: "DCAdults", name: "Adults", type: "number" },
    { systemName: "DCChilds", name: "Childs", type: "number" },
    { systemName: "TCAdults", name: "Adults", type: "number" },
    { systemName: "TCChilds", name: "Childs", type: "number" },
    { systemName: "TCInfants", name: "Infants", type: "number" },
    { systemName: "TCEscorts", name: "Escorts", type: "number" },
    { systemName: "TCNoShows", name: "No Shows", type: "number" },
    { systemName: "Notes", name: "Notes", type: "text" },
    { systemName: "Amount", name: "Amount", type: "number" },
    // { systemName: "CruiseLineTier", name: "Cruise Line Tier", type: "dropdown", options: [] },
    // { systemName: "CruiseLinePrice", name: "Cruise Line Price", type: "number" },
    // { systemName: "OperatorTier", name: "Operator Tier", type: "dropdown", options: [] },
    // { systemName: "OperatorPrice", name: "Operator Price", type: "number" }
];
let items = [];
let ports = {
    "Jeddah": 1,
    "Dammam": 2,
    "Yanbu": 3
}

const ShorexForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [callInfo, setCallInfo] = useState(null);
    const [itemStructures, setItemStructures] = useState({});
    const [shorexItems, setShorexItems] = useState(items || []);
    const [tourCodes, setTourCodes] = useState([]);
    const [tourOperators, setTourOperators] = useState([]);
    const [cruiseLineTiers, setCruiseLineTiers] = useState([]);
    const [operatorTiers, setOperatorTiers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showIsFinalModal, setShowIsFinalModal] = useState(false);
    const [conciliationSubmitted, setConciliationSubmitted] = useState(null);
    const [currentAction, setCurrentAction] = useState(null);
    const initialLoad = useRef(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No token found in local storage');
                }

                const callResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/shorexCall/${id}`, {
                    method: 'GET',
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json'
                    }
                });

                if (!callResponse.ok) {
                    throw new Error('Failed to fetch call data');
                }

                const callData = await callResponse.json();
                console.log("callData", callData);

                // Fetch port ID using the port title
                const portTitle = callData.fields.Portofcall;
                const portResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getPort/${encodeURIComponent(portTitle)}`, {
                    method: 'GET',
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json'
                    }
                });

                if (!portResponse.ok) {
                    throw new Error('Failed to fetch port ID');
                }

                const portId = await portResponse.json();
                console.log("Port ID:", portId);

                const shipResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getShip/${encodeURIComponent(callData.fields.ShipName)}`, {
                    method: 'GET',
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json'
                    }
                });

                if (!shipResponse.ok) {
                    throw new Error('Failed to fetch ship data');
                }

                const shipData = await shipResponse.json();
                console.log("shipData", shipData);

                // Update callInfo with the fetched port ID
                setCallInfo({ ...callData.fields, PortLookupId: portId, ShipNameLookupId: shipData.id, CruiseLineLookupId: shipData.CruiseLineLookupId });

                // Fetch tour operators using the port code
                await fetchTourOperators(portId);

                const tourCodesResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getTourCodes/${encodeURIComponent(portId)}`, {
                    method: 'GET',
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json'
                    }
                });

                if (!tourCodesResponse.ok) {
                    throw new Error('Failed to fetch tour codes');
                }

                const tourCodesData = await tourCodesResponse.json();
                console.log("tourCodesData", tourCodesData);

                setTourCodes(tourCodesData);
                structure = structure.map(item =>
                    item.systemName === "TourCode"
                        ? { ...item, options: tourCodesData.map(tc => ({ value: tc.Code, label: tc.isAddon ? tc.Code.substring(3) : tc.Code, id: tc.id })) }
                        : item
                );
                console.log("structure", structure);
                fetchConciliationData();
                fetchSubmittedConciliationData();
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const fetchConciliationData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getConciliationData/${id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch conciliation data');
            }
            const data = await response.json();
            console.log("conciliationData", data);
            const transformedData = data.map(item => ({
                id: item.id,
                CruiseLineLookupId: item.fields?.CruiseLineLookupId,
                ShipNameLookupId: item.fields?.ShipNameLookupId,
                Date: item.fields?.Date ? new Date(item.fields.Date).toISOString().split('T')[0] : null,
                PortLookupId: item.fields?.PortLookupId,
                TourOps: item.fields?.TourOperatorLookupId,
                TourOpsTitle: item.fields?.TourOperator,
                TourCode: item.fields?.TourCodeLookupId || '',
                TourName: item.fields?.Tour_x0020_Code_x003a__x0020_Tit,
                Bus: item.fields?.Bus,
                DepartureTime: item.fields?.DepartureTime ? item.fields.DepartureTime.slice(0, 16) : null,
                ReturnTime: item.fields?.ReturnTime ? item.fields.ReturnTime.slice(0, 16) : null,
                DCAdults: item.fields?.DispatchCountAdult,
                DCChilds: item.fields?.DispatchCountChild,
                TCAdults: item.fields?.TicketsCountAdult,
                TCChilds: item.fields?.TicketsCountChild,
                TCInfants: item.fields?.TicketsCountInfants,
                TCEscorts: item.fields?.TicketsCountEscorts,
                TCNoShows: item.fields?.TicketsCountNoShows,
                Notes: item.fields?.Notes,
                Amount: item.fields?.Amount,
                Changed: false
            }));
            console.log("transformedData", transformedData);
            setShorexItems(transformedData);
            for (const item of transformedData) {
                setItemStructures(prev => ({
                    ...prev,
                    [item.id]: structure.map(field =>
                        field.systemName === 'TourOps'
                            ? {
                                ...field,
                                options: [
                                    { value: item.TourOps, label: item.TourOpsTitle },
                                    ...field.options.filter(option => option.value !== item.TourOps)
                                ]
                            }
                            : field
                    )
                }));
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching conciliation data:', error);
            setError('Error fetching conciliation data');
            setLoading(false);
        }
    };

    const fetchSubmittedConciliationData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getSubmittedConciliationData/${id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch submitted conciliation data');
            }
            const data = await response.json();
            if (Object.keys(data).length === 0) {
                console.log('No submitted conciliation data found');
                return;
            }
            console.log("data", data);
            const transformedData = {
                id: data.id,
                Status: data.fields.Status,
                DocumentVersion: data.fields.DocumentVersion,
                isFinal: data.fields.isFinal,
                Modified: new Date(data.fields.Modified).toLocaleString(),
                Created: new Date(data.fields.Created).toLocaleString(),
                // CreatedBy: item.fields.CreatedBy.DisplayName,
                // ModifiedBy: item.fields.ModifiedBy.DisplayName
            };
            setConciliationSubmitted(transformedData);
            if (data.fields.isFinal) {
                setShowIsFinalModal(true);
            }
        } catch (error) {
            console.error('Error fetching submitted conciliation data:', error);
            setError('Error fetching submitted conciliation data');
        }
    };

    const fetchTourOperators = async (portCode) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }

            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getOpsTours/${encodeURIComponent(portCode)}`, {
                method: 'GET',
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch tour operators');
            }

            const data = await response.json();
            console.log("data", data);
            setTourOperators(data);

            // Update the structure for TourOps
            structure = structure.map(item =>
                item.systemName === "TourOps"
                    ? { ...item, options: data.map(operator => ({ value: operator.fields.OpsLookupId, label: operator.fields.Ops })) }
                    : item
            );
        } catch (error) {
            console.error("Error fetching tour operators:", error);
            setError("Error fetching tour operators");
        }
    };

    // useEffect(() => {
    //     if (initialLoad.current) {
    //         console.log('shorexItems', shorexItems);
    //         shorexItems.forEach(item => {
    //             setTimeout(() => {
    //                 handleItemChange(item.id, 'TourCode', item.TourCode);
    //             }, 1000);
    //         });
    //         if (shorexItems.length > 0) {
    //             initialLoad.current = false; // Set the flag to false after initial execution
    //         }
    //     }
    // }, [shorexItems]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    const handleAddItem = () => {
        const newItemId = shorexItems.length > 0 ? Math.max(...shorexItems.map(item => item.id)) + 1 : 1;
        setShorexItems(prevItems => [
            ...prevItems,
            {
                id: newItemId,
                ...Object.fromEntries(structure.map(item => [item.systemName, '']))
            }
        ]);
        setItemStructures(prev => ({
            ...prev,
            [newItemId]: [...structure]
        }));
    };

    const handleItemChange = (id, field, value) => {
        setShorexItems(shorexItems.map(item => {
            if (item.id === id) {
                const fieldStructure = structure.find(s => s.systemName === field);
                if (fieldStructure?.type === 'number') {
                    value = Math.max(0, Number(value));
                }
                const updatedItem = { ...item, [field]: value };

                // If TourCode changes, update TourName
                if (field === 'TourCode') {
                    const selectedTour = tourCodes.find(tc => tc.id === value);
                    console.log("selectedTour", selectedTour);
                    if (selectedTour) {
                        updatedItem.TourName = selectedTour.Title;
                        // fetchCruiseLineTiers(id, selectedTour.id, callInfo.CruiseLine);
                        // fetchTourOperators(id, callInfo.PortLookupId);
                    }
                }

                // If CruiseLineTier changes, update CruiseLinePrice
                // if (field === 'CruiseLineTier') {
                //     const selectedTier = cruiseLineTiers[id]?.find(tier => tier.id === value);
                //     console.log("selectedTier", selectedTier);
                //     if (selectedTier) {
                //         updatedItem.CruiseLinePrice = (updatedItem.TCAdults + updatedItem.TCChilds) * selectedTier.Price;
                //     }
                // }

                // if (field === 'TourOps') {
                //     console.log("updatedItem", updatedItem);
                //     fetchOperatorTiers(id, updatedItem.TourCode, value);
                // }

                // if (field === 'OperatorTier') {
                //     const selectedTier = operatorTiers[id]?.find(tier => tier.id === value);
                //     if (selectedTier) {
                //         updatedItem.OperatorPrice = (updatedItem.TCAdults + updatedItem.TCChilds) * selectedTier.Price;
                //     }
                // }

                // Only calculate TCNoShows if we're not directly editing it
                if (field !== 'TCNoShows') {
                    const dcAdults = Number(updatedItem.DCAdults) || 0;
                    const dcChilds = Number(updatedItem.DCChilds) || 0;
                    const tcAdults = Number(updatedItem.TCAdults) || 0;
                    const tcChilds = Number(updatedItem.TCChilds) || 0;
                    updatedItem.TCNoShows = (dcAdults + dcChilds) - (tcAdults + tcChilds);
                }

                updatedItem.Changed = true;

                return updatedItem;
            }
            return item;
        }));
    };

    const fetchCruiseLineTiers = async (itemId, tourCode, cruiseLineId) => {
        try {
            console.log(itemId, tourCode, cruiseLineId);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }

            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getCruiseLineTiers/${encodeURIComponent(tourCode)}/${encodeURIComponent(cruiseLineId)}`, {
                method: 'GET',
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch cruise line tiers');
            }

            const data = await response.json();
            console.log("data", data);
            setCruiseLineTiers(prevTiers => ({
                ...prevTiers,
                [itemId]: data
            }));

            setItemStructures(prev => ({
                ...prev,
                [itemId]: prev[itemId].map(item =>
                    item.systemName === "CruiseLineTier"
                        ? { ...item, options: data.map(tier => ({ value: tier.id, label: tier.Title })) }
                        : item
                )
            }));

            // structure = structure.map(item =>
            //     item.systemName === "CruiseLineTier"
            //         ? { ...item, options: data.map(tier => ({ value: tier.id, label: tier.Title })) }
            //         : item
            // );
            // console.log(structure);

            // Update the structure for this specific item
            // setShorexItems(prevItems => prevItems.map(item => {
            //     if (item.id === itemId) {
            //         return {
            //             ...item,
            //             CruiseLineTier: '',
            //             CruiseLinePrice: ''
            //         };
            //     }
            //     return item;
            // }));
        } catch (error) {
            console.error("Error fetching cruise line tiers:", error);
            setError("Error fetching cruise line tiers");
        }
    };

    const fetchOperatorTiers = async (itemId, tourCode, operatorId) => {
        try {
            console.log(itemId, tourCode, operatorId);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }

            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getOperatorTiers/${encodeURIComponent(tourCode)}/${encodeURIComponent(operatorId)}`, {
                method: 'GET',
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch operator tiers');
            }

            const data = await response.json();
            console.log("data", data);
            setOperatorTiers(prevTiers => ({
                ...prevTiers,
                [itemId]: data
            }));

            setItemStructures(prev => ({
                ...prev,
                [itemId]: prev[itemId].map(item =>
                    item.systemName === "OperatorTier"
                        ? { ...item, options: data.map(tier => ({ value: tier.id, label: tier.Title })) }
                        : item
                )
            }));

            // structure = structure.map(item =>
            //     item.systemName === "OperatorTier"
            //         ? { ...item, options: data.map(tier => ({ value: tier.id, label: tier.Title })) }
            //         : item
            // );
            // console.log(structure);

            // Update the structure for this specific item if needed
            // Similar to what you did for CruiseLineTier
        } catch (error) {
            console.error("Error fetching operator tiers:", error);
            setError("Error fetching operator tiers");
        }
    };

    const handleRemoveItem = (id) => {
        setShorexItems(shorexItems.filter(item => item.id !== id));
    };

    const handleMouseDown = (e) => {
        const section = e.currentTarget;
        section.style.cursor = 'grabbing';
        section.style.userSelect = 'none';

        const startX = e.pageX - section.offsetLeft;
        const scrollLeft = section.scrollLeft;

        const onMouseMove = (e) => {
            const x = e.pageX - section.offsetLeft;
            const walk = (x - startX) * 2; // Adjust the multiplier for faster/slower scrolling
            section.scrollLeft = scrollLeft - walk;
        };

        const onMouseUp = () => {
            section.style.cursor = 'grab';
            section.style.removeProperty('user-select');
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }

            console.log("conciliationSubmitted", conciliationSubmitted);

            let requestBody = { callId: callInfo.FID.toString() };
            if (conciliationSubmitted && conciliationSubmitted.DocumentVersion !== undefined) {
                requestBody.documentVersion = conciliationSubmitted.DocumentVersion + 1;
            }
            console.log("requestBody", requestBody);
            // return
            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/submitConciliation`, {
                method: 'POST',
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error(`Failed to submit conciliation: ${response.statusText}`);
            }

            const result = await response.json();
            console.log('Conciliation submitted successfully:', result);
            setSubmitting(false);
        } catch (error) {
            console.error('Error submitting conciliation:', error);
            setError('Error submitting conciliation');
            setSubmitting(false);
        }
    };

    const handleFinalAndLock = async () => {
        setSubmitting(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }

            console.log("conciliationSubmitted", conciliationSubmitted);

            let requestBody = {
                callId: callInfo.FID.toString(),
                isFinal: true
            };
            if (conciliationSubmitted && conciliationSubmitted.DocumentVersion !== undefined) {
                requestBody.documentVersion = conciliationSubmitted.DocumentVersion + 1;
            }

            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/submitConciliation`, {
                method: 'POST',
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error(`Failed to submit final conciliation: ${response.statusText}`);
            }

            const result = await response.json();
            console.log('Final conciliation submitted successfully:', result);
            setSubmitting(false);
            setShowSuccess(true);
            await fetchSubmittedConciliationData(callInfo.FID.toString());
        } catch (error) {
            console.error('Error submitting final conciliation:', error);
            setError('Error submitting final conciliation');
            setSubmitting(false);
        }
    };

    const handleSaveDraft = async () => {
        setSubmitting(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }

            // Transform shorexItems to the required format
            let transformedItems = shorexItems.filter(item => item.Changed).map(item => ({
                id: item.id,
                CruiseLineLookupId: parseInt(callInfo.CruiseLineLookupId),
                ShipNameLookupId: parseInt(callInfo.ShipNameLookupId),
                Date: new Date().toISOString().split('T')[0],
                PortLookupId: parseInt(callInfo.PortLookupId),
                TourOperatorLookupId: parseInt(item.TourOps),
                TourCodeLookupId: parseInt(item.TourCode),
                Bus: item.Bus,
                DepartureTime: item.DepartureTime,
                ReturnTime: item.ReturnTime,
                DispatchCountAdult: item.DCAdults,
                DispatchCountChild: item.DCChilds,
                TicketsCountAdult: item.TCAdults,
                TicketsCountChild: item.TCChilds,
                TicketsCountInfants: item.TCInfants,
                TicketsCountEscorts: item.TCEscorts,
                TicketsCountNoShows: item.TCNoShows,
                Notes: item.Notes,
                Amount: item.Amount || 0,
                CallCode: callInfo.FID.toString(),
                // CruiseLineTierLookupId: parseInt(item.CruiseLineTier),
                // CruiseLineTotal: item.CruiseLinePrice,
                // OperatorTiersLookupId: parseInt(item.OperatorTier),
                // OperatorTotal: item.OperatorPrice,
                // CallLookupId: parseInt(callInfo.id)
            }));

            console.log("transformedItems", transformedItems);

            // Filter out items with null values
            transformedItems = transformedItems.map(item => {
                return Object.fromEntries(
                    Object.entries(item).filter(([key, value]) =>
                        value !== null && value !== '' &&
                        (typeof value !== 'number' || !isNaN(value))
                    )
                );
            });

            console.log("transformedItems", transformedItems);

            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/saveDraft`, {
                method: 'POST',
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(transformedItems)
            });

            if (!response.ok) {
                throw new Error('Failed to save draft');
            }

            const result = await response.json();
            console.log('Draft saved successfully:', result);
            setSubmitting(false);
            setShowSuccess(true);
        } catch (error) {
            console.error('Error saving draft:', error);
            setSubmitting(false);
        }
    };

    const handleConfirmAction = async () => {
        setShowConfirmation(false);
        await handleSaveDraft();
        if (currentAction === 'submit') {
            await handleSubmit();
        } else if (currentAction === 'finalize') {
            await handleFinalAndLock();
        }
        await fetchSubmittedConciliationData();
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen"><div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div></div>;
    }

    if (error) {
        return <div className="text-center text-red-500 dark:text-red-400">{error}</div>;
    }

    const isFinal = conciliationSubmitted?.isFinal;

    return (
        <>
            <div className="container mx-auto p-4">
                <button onClick={() => navigate(-1)} className="bg-blue-500 hover:bg-blue-700 text-white mb-4 font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700">Back</button>

                <header className="mb-8">
                    <h1 className="text-3xl font-bold mb-4">Call Information</h1>
                    {callInfo && (
                        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4">
                            <div className="flex flex-wrap gap-2">
                                <span className="inline-block bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Title: {callInfo.ETA.split('T')[0]} {callInfo.Portofcall} {callInfo.CruiseLine} {callInfo.ShipName}</span>
                                <span className="inline-block bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">ETA: {formatDate(callInfo.ETA)}</span>
                                <span className="inline-block bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">ETD: {formatDate(callInfo.ETD)}</span>
                                <span className="inline-block bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Cruise Line: {callInfo.CruiseLine}</span>
                                <span className="inline-block bg-purple-100 text-purple-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">Ship Name: {callInfo.ShipName}</span>
                                <span className="inline-block bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">Port of Call: {callInfo.Portofcall}</span>
                                <span className="inline-block bg-pink-100 text-pink-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">Call Type: {callInfo.CallType}</span>
                            </div>
                        </div>
                    )}
                </header>

                <section className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">Conciliation Status</h2>
                    {conciliationSubmitted ? (
                        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4">
                            <div className="flex flex-wrap gap-2">
                                <span className="inline-block bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Status: {conciliationSubmitted.Status}</span>
                                <span className="inline-block bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Document Version: {conciliationSubmitted.DocumentVersion}</span>
                                <span className="inline-block bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Created: {conciliationSubmitted.Created}</span>
                                <span className="inline-block bg-purple-100 text-purple-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">Last Modified: {conciliationSubmitted.Modified}</span>
                            </div>
                        </div>
                    ) : (
                        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4">
                            <p className="text-gray-500 dark:text-gray-400">No conciliation submitted yet.</p>
                        </div>
                    )}
                </section>

                <section className="overflow-x-auto" onMouseDown={handleMouseDown} style={{ cursor: 'grab' }}>
                    <h2 className="text-2xl font-bold mb-4">Shorex Items</h2>
                    <div className="relative shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th colSpan="6" className="text-center py-2 bg-blue-200 text-gray-700 dark:bg-blue-900 dark:text-gray-300">General Info</th>
                                    <th colSpan="2" className="text-center py-2 bg-orange-200 text-gray-700 dark:bg-orange-900 dark:text-gray-300">Dispatch Counts</th>
                                    <th colSpan="5" className="text-center py-2 bg-yellow-100 text-gray-700 dark:bg-yellow-800 dark:text-gray-300">Tickets Count</th>
                                    <th colSpan="2" className="text-center py-2 bg-gray-200 text-gray-700 dark:bg-gray-800 dark:text-gray-300">Service</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    {structure.map((item, index) => (
                                        <th key={index} scope="col" className="py-3 px-6 whitespace-nowrap text-center">
                                            {item.name}
                                        </th>
                                    ))}
                                    <th scope="col" className="py-3 px-6 whitespace-nowrap">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shorexItems.map((item) => (
                                    <tr key={item.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        {itemStructures[item.id]?.map((field) => (
                                            <td key={field.systemName} className="py-4 px-6 whitespace-nowrap">
                                                {field.systemName === 'TCNoShows' ? (
                                                    <input
                                                        type="number"
                                                        value={item[field.systemName] || 0}
                                                        onChange={(e) => handleItemChange(item.id, field.systemName, e.target.value)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        style={{ minWidth: '100px' }}
                                                        min="0"
                                                        disabled={isFinal}
                                                    />
                                                ) : field.type === 'dropdown' ? (
                                                    <select
                                                        value={item[field.systemName]}
                                                        onChange={(e) => handleItemChange(item.id, field.systemName, e.target.value)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        style={{ minWidth: '150px' }}
                                                        disabled={isFinal}
                                                    >
                                                        <option value="">Select {field.name}</option>
                                                        {field.options.map((option) => (
                                                            <option key={field.systemName === "TourCode" ? option.id : option.value} value={field.systemName === "TourCode" ? option.id : option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <input
                                                        type={field.type}
                                                        value={item[field.systemName]}
                                                        onChange={(e) => handleItemChange(item.id, field.systemName, e.target.value)}
                                                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${field.readOnly || (field.systemName === 'Amount' && !tourCodes.find(tc => tc.id === item.TourCode)?.isAddon) ? 'cursor-not-allowed' : ''}`}
                                                        style={{ minWidth: field.type === 'text' ? '150px' : '100px' }}
                                                        min={field.type === 'number' ? '0' : undefined}
                                                        readOnly={field.readOnly || (field.systemName === 'Amount' && !tourCodes.find(tc => tc.id === item.TourCode)?.isAddon) || isFinal}
                                                        disabled={field.systemName === 'Amount' && !tourCodes.find(tc => tc.id === item.TourCode)?.isAddon || isFinal}
                                                    />
                                                )}
                                            </td>
                                        ))}
                                        <td className="py-4 px-6 whitespace-nowrap">
                                            <button onClick={() => handleRemoveItem(item.id)} className="font-medium text-red-600 dark:text-red-500 hover:underline" disabled={isFinal}>Remove</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </section>
                <div className="mt-4 flex flex-col space-y-4">
                    <div className="flex space-x-4">
                        <button
                            onClick={handleAddItem}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700"
                            disabled={isFinal}
                        >
                            Add Item
                        </button>
                        <button
                            onClick={handleSaveDraft}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700"
                            disabled={isFinal}
                        >
                            Save Draft
                        </button>
                        <button
                            onClick={() => {
                                setCurrentAction('submit');
                                setShowConfirmation(true);
                            }}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded dark:bg-green-600 dark:hover:bg-green-700"
                            disabled={isFinal}
                        >
                            Submit
                        </button>
                    </div>
                    <button
                        onClick={() => {
                            setCurrentAction('finalize');
                            setShowConfirmation(true);
                        }}
                        className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded dark:bg-purple-600 dark:hover:bg-purple-700 w-fit"
                        disabled={isFinal}
                    >
                        Finalize and Lock
                    </button>
                </div>
            </div>
            {submitting && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            )}
            {showSuccess && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center">
                        <svg className="w-16 h-16 text-green-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Success!</h2>
                        <p className="text-gray-700 dark:text-gray-300 mb-4">Your form has been submitted successfully.</p>
                        <button
                            onClick={() => {
                                setShowSuccess(false);
                                // navigate(-1);
                            }}
                            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded dark:bg-green-600 dark:hover:bg-green-700"
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
            {showConfirmation && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center">
                        <svg className="w-16 h-16 text-yellow-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                        </svg>
                        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Confirm Submission</h2>
                        <p className="text-gray-700 dark:text-gray-300 mb-4">Are you sure you want to submit this form?</p>
                        <div className="flex space-x-4">
                            <button
                                onClick={handleConfirmAction}
                                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded dark:bg-green-600 dark:hover:bg-green-700"
                                disabled={isFinal}
                            >
                                Confirm
                            </button>
                            <button
                                onClick={() => setShowConfirmation(false)}
                                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded dark:bg-red-600 dark:hover:bg-red-700"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isFinal && showIsFinalModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-8 max-w-md w-full">
                        <div className="flex justify-between items-start mb-4">
                            <svg className="w-8 h-8 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                            </svg>
                            <button onClick={() => setShowIsFinalModal(false)} className="text-gray-500 hover:text-gray-700">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                        </div>
                        <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">Final Submission</h2>
                        <p className="text-gray-700 dark:text-gray-300 mb-4">
                            This form has been marked as final and can no longer be edited. If you need to make changes, please contact IT to unlock it.
                        </p>
                        <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg mb-4">
                            <h3 className="font-semibold text-gray-900 dark:text-white mb-2">Status Information:</h3>
                            <p className="text-sm text-gray-700 dark:text-gray-300">Status: {conciliationSubmitted?.Status}</p>
                            <p className="text-sm text-gray-700 dark:text-gray-300">Document Version: {conciliationSubmitted?.DocumentVersion}</p>
                            <p className="text-sm text-gray-700 dark:text-gray-300">Last Modified: {conciliationSubmitted?.Modified}</p>
                            <p className="text-sm text-gray-700 dark:text-gray-300">Created: {conciliationSubmitted?.Created}</p>
                        </div>
                        <button
                            onClick={() => setShowIsFinalModal(false)}
                            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded dark:bg-blue-600 dark:hover:bg-blue-700"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default ShorexForm;