import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';

const FormPage = () => {
  const { id } = useParams();
  const [callInfo, setCallInfo] = useState(null);
  const [masterReportItems, setMasterReportItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found in local storage');
        }

        const [callResponse, masterReportResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/call/${id}`, {
            method: 'GET',
            headers: {
              'authorization': token,
              'Content-Type': 'application/json'
            }
          }),
          fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/masterReport?callId=${id}`, {
            method: 'GET',
            headers: {
              'authorization': token,
              'Content-Type': 'application/json'
            }
          })
        ]);

        if (!callResponse.ok || !masterReportResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const [callData, masterReportData] = await Promise.all([
          callResponse.json(),
          masterReportResponse.json()
        ]);

        setCallInfo(callData.fields);
        setMasterReportItems(masterReportData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <div className="flex justify-center items-center h-screen"><div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div></div>;
  }

  if (error) {
    return <div className="text-center text-red-500 dark:text-red-400">{error}</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const renderActionButton = (status, id, type) => {
    if (status === 'Rejected') {
      return (
        <Link to={`/form/${id}/${type}/edit`} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded text-xs dark:bg-gray-600 dark:hover:bg-gray-700">
          View
        </Link>
      );
    } else {
      return (
        <Link to={`/form/${id}/${type}/edit`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs dark:bg-gray-600 dark:hover:bg-gray-700">
          View
        </Link>
      );
    }
  };

  const renderTable = (type) => {
    const items = masterReportItems.filter(item => item.Pre_x002d_Arrival_x002f_Post_x00 === type);
    return (
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">Title</th>
              <th scope="col" className="py-3 px-6">Status</th>
              <th scope="col" className="py-3 px-6">Modified</th>
              <th scope="col" className="py-3 px-6">Action</th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 ? (
              items.map((item, index) => (
                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td className="py-4 px-6">{item.Title}</td>
                  <td className="py-4 px-6">
                    <span className={`inline-block text-xs font-medium px-2.5 py-0.5 rounded
                      ${item.Status === 'Approved' ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300' :
                        item.Status === 'Rejected' ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300' :
                          'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'}`}>
                      {item.Status}
                    </span>
                  </td>
                  <td className="py-4 px-6">{formatDate(item.Modified)}</td>
                  <td className="py-4 px-6">
                    {renderActionButton(item.Status, id, type.toLowerCase() === 'pre-arrival' ? 'pre' : 'post')}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td colSpan="4" className="py-4 px-6 text-center">No {type} form available.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
  console.log(masterReportItems);
  const hasPreArrivalForm = masterReportItems.some(item => item.Pre_x002d_Arrival_x002f_Post_x00 === 'Pre-Arrival');
  const hasPostDepartureForm = masterReportItems.some(item => item.Pre_x002d_Arrival_x002f_Post_x00 === 'Post Departure') || !hasPreArrivalForm || masterReportItems.some(item => item.Status === 'Pending') || masterReportItems.some(item => item.Status === 'Rejected');

  return (
    <div className="container mx-auto p-4">
      <button onClick={() => navigate(-1)} className="bg-blue-500 hover:bg-blue-700 text-white mb-1 font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700">Back</button>
      <header className="mb-8">
        <h1 className="text-3xl font-bold mb-4">Call Information</h1>
        {callInfo ? (
          <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4">
            <div className="flex flex-wrap gap-2">
              <span className="inline-block bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Title: {callInfo.Title}</span>
              <span className="inline-block bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">ETA: {formatDate(callInfo.ETA)}</span>
              <span className="inline-block bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">ETD: {formatDate(callInfo.ETD)}</span>
              <span className="inline-block bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Cruise Line: {callInfo.CruiseLine}</span>
              <span className="inline-block bg-purple-100 text-purple-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">Ship Name: {callInfo.ShipName}</span>
              <span className="inline-block bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">Port of Call: {callInfo.Portofcall}</span>
              <span className="inline-block bg-pink-100 text-pink-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">Call Type: {callInfo.CallType}</span>
            </div>
          </div>
        ) : (
          <p>No call information available.</p>
        )}
      </header>

      <section>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Pre-Arrival Form</h2>
          <Link to={`/form/${id}/pre/new`} className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700 ${hasPreArrivalForm ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={(e) => hasPreArrivalForm && e.preventDefault()}>
            Add
          </Link>
        </div>
        {renderTable('Pre-Arrival')}
      </section>

      <section className="mt-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Post-Departure Form</h2>
          <Link to={`/form/${id}/post/new`} className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700 ${hasPostDepartureForm ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={(e) => hasPostDepartureForm && e.preventDefault()}>
            Add
          </Link>
        </div>
        {renderTable('Post Departure')}
      </section>
    </div>
  );
};

export default FormPage;
