import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const NewFormPage = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const [callInfo, setCallInfo] = useState(null);
  const [lookups, setLookups] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    REPORT_LIST: {
      Title: '',
      ShipNameLookupId: '',
      CruiseLineLookupId: '',
      Port: '',
      ReportbyLookupId: '',
      Reportpreparedon_x0028_date_x002: new Date().toISOString().slice(0, 16),
      GRT: '',
      ETA_x0028_Pilotstation_x0029_: '',
      ETD_x0028_Berth_x0029_: '',
      Berthnumber: '',
      TypeofCall: '',
      Previousportofcall: '',
      Nextportofcall: '',
      Pilotonboard: '',
      Alllinesfast: '',
      Healthauthorityonboard: '',
      Customsauthorityonboard: '',
      Shipcleared: '',
      Firstcrew_x002f_paxashore: '',
      Allshipservicescompleted: '',
      Allpaxcrewonboard: '',
      Portclearanceissued: '',
      Shipdeparture: '',
      Pre_x002d_Arrival_x002f_Post_x00: type === 'pre' ? 'Pre-Arrival' : 'Post Departure',
      CallIdLookupId: id,
    },
    DESCRIPTION_LIST: [],
    DISEMBARKATION_LIST: [],
    EMBARKATION_LIST: [],
    PIER_SERVICES_LIST: [],
    PORT_SERVICES_LIST: []
  });
  const [labelHeaders, setLabelHeaders] = useState({
    Title: 'Title',
    ShipNameLookupId: 'Ship Name',
    CruiseLineLookupId: 'Cruise Line',
    Port: 'Port',
    Reportpreparedon_x0028_date_x002: 'Report Prepared On',
    ETA_x0028_Pilotstation_x0029_: 'ETA (Pilot Station)',
    Berthnumber: 'Berth Number',
    Previousportofcall: 'Previous Port of Call',
    GRT: 'GRT',
    ETD_x0028_Berth_x0029_: 'ETD (Berth)',
    TypeofCall: 'Type of Call',
    Nextportofcall: 'Next Port of Call',
    Firstcrew_x002f_paxashore: 'First Crew/Pax Ashore',
    Allpaxcrewonboard: 'All Pax/Crew Onboard',
    Shipdeparture: 'Ship Departure',
    Pre_x002d_Arrival_x002f_Post_x00: 'Pre-Arrival/Post-Departure',
    CallIdLookupId: 'Call ID',
    ReportbyLookupId: 'Report By',
    Pilotonboard: 'Pilot Onboard',
    Alllinesfast: 'All Lines Fast',
    Healthauthorityonboard: 'Health Authority Onboard',
    Customsauthorityonboard: 'Customs Authority Onboard',
    Shipcleared: 'Ship Cleared',
    Portclearanceissued: 'Port Clearance Issued',
    Allshipservicescompleted: 'All Ship Services Completed',
    StartTime: 'Start Time',
    FinishTime: 'Finish Time',
    ServiceProvider_x002f_Supplier: 'Service Provider/Supplier',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found in local storage');
        }

        // First fetch call data and lookups regardless of draft
        const callResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/call/${id}`, {
          method: 'GET',
          headers: {
            'authorization': token,
            'Content-Type': 'application/json'
          }
        });

        if (!callResponse.ok) {
          throw new Error('Failed to fetch call data');
        }

        const callData = await callResponse.json();
        console.log(callData);
        setCallInfo(callData.fields);

        // Fetch lookups
        const lookupsResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/lookups?port=${callData.fields.Portofcall}`, {
          method: 'GET',
          headers: {
            'authorization': token,
            'Content-Type': 'application/json'
          }
        });

        if (!lookupsResponse.ok) {
          throw new Error('Failed to fetch lookups');
        }

        const lookupsData = await lookupsResponse.json();
        console.log(lookupsData);
        setLookups(lookupsData);

        // Check for draft after fetching necessary lookups
        const draftKey = `form-draft-${id}-${type}`;
        const savedDraft = localStorage.getItem(draftKey);

        if (savedDraft) {
          setFormData(JSON.parse(savedDraft));
          setIsDraft(true);
          setLoading(false);
          return; // Exit after loading draft and lookups
        }

        if (type === 'post') {
          const formResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/form/${id}/pre`, {
            method: 'GET',
            headers: {
              'authorization': token,
              'Content-Type': 'application/json'
            }
          });

          if (!formResponse.ok) {
            throw new Error('Failed to fetch form data');
          }

          const formData = await formResponse.json();
          console.log(formData);
          setFormData(prevState => ({
            ...prevState,
            REPORT_LIST: {
              ...prevState.REPORT_LIST,
              Title: formData.REPORT_LIST.Title,
              ShipNameLookupId: formData.REPORT_LIST.ShipNameLookupId,
              CruiseLineLookupId: formData.REPORT_LIST.CruiseLineLookupId,
              Port: formData.REPORT_LIST.Port,
              Previousportofcall: formData.REPORT_LIST.Previousportofcall,
              TypeofCall: formData.REPORT_LIST.TypeofCall,
              Nextportofcall: formData.REPORT_LIST.Nextportofcall,
              ReportbyLookupId: formData.REPORT_LIST.ReportbyLookupId,
              ETA_x0028_Pilotstation_x0029_: formData.REPORT_LIST.ETA_x0028_Pilotstation_x0029_.slice(0, 16),
              ETD_x0028_Berth_x0029_: formData.REPORT_LIST.ETD_x0028_Berth_x0029_.slice(0, 16),
              GRT: formData.REPORT_LIST.GRT,
              Berthnumber: formData.REPORT_LIST.Berthnumber,
              Reportpreparedon_x0028_date_x002: formData.REPORT_LIST.Reportpreparedon_x0028_date_x002.slice(0, 16),
              Shipdeparture: formData.REPORT_LIST.Shipdeparture,
              Pilotonboard: formData.REPORT_LIST.Pilotonboard,
              Alllinesfast: formData.REPORT_LIST.Alllinesfast,
              Healthauthorityonboard: formData.REPORT_LIST.Healthauthorityonboard,
              Customsauthorityonboard: formData.REPORT_LIST.Customsauthorityonboard,
              Shipcleared: formData.REPORT_LIST.Shipcleared,
              Firstcrew_x002f_paxashore: formData.REPORT_LIST.Firstcrew_x002f_paxashore,
              Allpaxcrewonboard: formData.REPORT_LIST.Allpaxcrewonboard,
              Portclearanceissued: formData.REPORT_LIST.Portclearanceissued,
              Allshipservicescompleted: formData.REPORT_LIST.Allshipservicescompleted,
            },
            DESCRIPTION_LIST: formData.DESCRIPTION_LIST,
            DISEMBARKATION_LIST: formData.DISEMBARKATION_LIST,
            EMBARKATION_LIST: formData.EMBARKATION_LIST,
            PIER_SERVICES_LIST: formData.PIER_SERVICES_LIST,
            PORT_SERVICES_LIST: formData.PORT_SERVICES_LIST
          }));

          const fetchLookupValues = async (listId, itemId) => {
            try {
              const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/lookup/${listId}/${itemId}`, {
                headers: {
                  'authorization': token,
                  'Content-Type': 'application/json'
                }
              });
              if (!response.ok) {
                throw new Error('Failed to fetch lookup value');
              }
              return await response.json();
            } catch (error) {
              console.error('Error fetching lookup value:', error);
              return null;
            }
          };

          // Fetch lookup values for ShipName, CruiseLine, ReportBy, and all list items
          const [shipLookup, cruiseLineLookup, reportByLookup] = await Promise.all([
            fetchLookupValues(process.env.REACT_APP_SHIP_LOOKUP_LIST_ID, formData.REPORT_LIST.ShipNameLookupId),
            fetchLookupValues(process.env.REACT_APP_CRUISE_LOOKUP_LIST_ID, formData.REPORT_LIST.CruiseLineLookupId),
            fetchLookupValues(process.env.REACT_APP_USERS_LIST_ID, formData.REPORT_LIST.ReportbyLookupId)
          ]);

          const fetchListLookups = async (listName, lookupListId, lookupIdKey) => {
            const lookups = await Promise.all(
              formData[listName].map(item => fetchLookupValues(lookupListId, item[lookupIdKey]))
            );
            return formData[listName].map((item, index) => ({
              ...item,
              [lookupIdKey]: lookups[index] ? lookups[index].Title : item[lookupIdKey]
            }));
          };

          const [
            DESCRIPTION_LIST,
            DISEMBARKATION_LIST,
            EMBARKATION_LIST,
            PIER_SERVICES_LIST,
            PORT_SERVICES_LIST
          ] = await Promise.all([
            fetchListLookups('DESCRIPTION_LIST', process.env.REACT_APP_DESCRIPTION_LOOKUP_LIST_ID, 'DescriptionItemLookupId'),
            fetchListLookups('DISEMBARKATION_LIST', process.env.REACT_APP_DISEMBARKATION_LOOKUP_LIST_ID, 'DisembarkationItemLookupId'),
            fetchListLookups('EMBARKATION_LIST', process.env.REACT_APP_EMBARKATION_LOOKUP_LIST_ID, 'EmbarkationItemLookupId'),
            fetchListLookups('PIER_SERVICES_LIST', process.env.REACT_APP_PIER_SERVICES_LOOKUP_LIST_ID, 'PierServicesItemLookupId'),
            fetchListLookups('PORT_SERVICES_LIST', process.env.REACT_APP_PORT_SERVICES_LOOKUP_LIST_ID, 'ServicesRenderedLookupId')
          ]);

          // Update formData with fetched lookup values
          setFormData(prevState => ({
            ...prevState,
            REPORT_LIST: {
              ...prevState.REPORT_LIST,
              ShipNameLookupId: shipLookup ? shipLookup.ShipName : prevState.REPORT_LIST.ShipNameLookupId,
              CruiseLineLookupId: cruiseLineLookup ? cruiseLineLookup.Title : prevState.REPORT_LIST.CruiseLineLookupId,
              ReportbyLookupId: reportByLookup ? reportByLookup.Name : prevState.REPORT_LIST.ReportbyLookupId
            },
            DESCRIPTION_LIST,
            DISEMBARKATION_LIST,
            EMBARKATION_LIST,
            PIER_SERVICES_LIST,
            PORT_SERVICES_LIST
          }));
          setLoading(false);
        } else {
          setFormData(prevState => ({
            ...prevState,
            REPORT_LIST: {
              ...prevState.REPORT_LIST,
              Title: callData.fields.Title,
              ShipNameLookupId: callData.fields.ShipName,
              CruiseLineLookupId: callData.fields.CruiseLine,
              Port: callData.fields.Portofcall,
              Previousportofcall: callData.fields.PreviousPort,
              TypeofCall: callData.fields.CallType,
              Nextportofcall: callData.fields.NextPort,
              ReportbyLookupId: JSON.parse(localStorage.getItem('user')).name,
              ETA_x0028_Pilotstation_x0029_: callData.fields.ETA.slice(0, 16),
              ETD_x0028_Berth_x0029_: callData.fields.ETD.slice(0, 16),
            }
          }));
          setLoading(false);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, [id, type]);

  const handleReportChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      REPORT_LIST: { ...prevState.REPORT_LIST, [name]: value }
    }));
  };

  const handleAddItem = (listName) => {
    const newItem = {
      id: Date.now(),
      [listName === 'DESCRIPTION_LIST' ? 'DescriptionItem' :
        listName === 'DISEMBARKATION_LIST' ? 'DisembarkationItem' :
          listName === 'EMBARKATION_LIST' ? 'EmbarkationItem' :
            listName === 'PIER_SERVICES_LIST' ? 'PierServicesItem' :
              'ServicesRendered']: '',
      StartTime: '',
      FinishTime: '',
      Count: '',
      Quantity: '',
      Comments: '',
      Passenger: '',
      Crew: '',
      'ServiceProvider_x002f_Supplier': '',
      CallId: id
    };
    setFormData(prevState => ({
      ...prevState,
      [listName]: [...prevState[listName], newItem]
    }));
  };

  const handleItemChange = (listName, id, field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [listName]: prevState[listName].map(item =>
        item.id === id ? { ...item, [field]: value } : item
      )
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowConfirmation(false);
    setSubmitting(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found in local storage');
      }

      // Format ETA and ETD to ISO 8601
      const formattedData = {
        ...formData,
        REPORT_LIST: {
          ...formData.REPORT_LIST,
          ReportbyLookupId: JSON.parse(localStorage.getItem('user')).id,
          ETA_x0028_Pilotstation_x0029_: formData.REPORT_LIST.ETA_x0028_Pilotstation_x0029_ ? new Date(formData.REPORT_LIST.ETA_x0028_Pilotstation_x0029_).toISOString() : null,
          ETD_x0028_Berth_x0029_: formData.REPORT_LIST.ETD_x0028_Berth_x0029_ ? new Date(formData.REPORT_LIST.ETD_x0028_Berth_x0029_).toISOString() : null,
          Reportpreparedon_x0028_date_x002: formData.REPORT_LIST.Reportpreparedon_x0028_date_x002 ? new Date(formData.REPORT_LIST.Reportpreparedon_x0028_date_x002).toISOString() : null
        }
      };

      // Remove id from list items
      const listsToProcess = ['DESCRIPTION_LIST', 'DISEMBARKATION_LIST', 'EMBARKATION_LIST', 'PIER_SERVICES_LIST', 'PORT_SERVICES_LIST'];

      listsToProcess.forEach(listName => {
        formattedData[listName] = filterUnwantedParams(listName, formattedData[listName]);
      });

      console.log(formattedData);

      const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/submitForm`, {
        method: 'POST',
        headers: {
          'authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedData),
      });
      if (response.ok) {
        const draftKey = `form-draft-${id}-${type}`;
        localStorage.removeItem(draftKey);
        setIsDraft(false);

        setSubmitting(false);
        setShowSuccess(true);
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form');
    } finally {
      setSubmitting(false);
    }
  };

  const saveDraft = () => {
    const draftKey = `form-draft-${id}-${type}`;
    localStorage.setItem(draftKey, JSON.stringify(formData));
    setIsDraft(true);
    // Show temporary success message
    alert('Draft saved successfully');
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen"><div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div></div>;
  }

  if (error) {
    return <div className="text-center text-red-500 dark:text-red-400">{error}</div>;
  }

  const columns = {
    DESCRIPTION_LIST: ['DescriptionItemLookupId', 'Passenger', 'Crew', 'Comments'],
    DISEMBARKATION_LIST: ['DisembarkationItemLookupId', 'StartTime', 'FinishTime', 'Count', 'Comments'],
    EMBARKATION_LIST: ['EmbarkationItemLookupId', 'StartTime', 'FinishTime', 'Count', 'Comments'],
    PIER_SERVICES_LIST: ['PierServicesItemLookupId', 'StartTime', 'FinishTime', 'Quantity', 'Comments'],
    PORT_SERVICES_LIST: ['ServicesRenderedLookupId', 'ServiceProvider_x002f_Supplier', 'Quantity', 'Comments']
  };
  const renderTable = (listName) => {

    return (
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {columns[listName].map((column, index) => index === 0 ? (
                <th key={index} scope="col" className="px-6 py-3 w-1/4">Items</th>
              ) : (
                <th key={index} scope="col" className="px-6 py-3">{labelHeaders.hasOwnProperty(column) ? labelHeaders[column] : column}</th>
              ))}
              <th scope="col" className="px-6 py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {formData[listName].map((item, index) => (
              <tr key={item.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                {columns[listName].map((column, colIndex) => (
                  <td key={colIndex} className="px-6 py-4">
                    {column.includes('Item') || column === 'ServicesRenderedLookupId' ? (
                      <select
                        value={item[column]}
                        onChange={(e) => handleItemChange(listName, item.id, column, e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Select an item</option>
                        {listName.toLowerCase().replace('_list', '') === "port_services" ? lookups['portServices'].map((lookup) => (
                          <option key={lookup.id} value={lookup.title}>{lookup.title}</option>
                        )) : listName.toLowerCase().replace('_list', '') === "pier_services" ? lookups['pierServices'].map((lookup) => (
                          <option key={lookup.id} value={lookup.title}>{lookup.title}</option>
                        )) : lookups[listName.toLowerCase().replace('_list', '')] && lookups[listName.toLowerCase().replace('_list', '')].map((lookup) => (
                          <option key={lookup.id} value={lookup.title}>{lookup.title}</option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type={column.includes('Time') ? 'time' : column === 'Count' || column === 'Quantity' || column === 'Passenger' || column === 'Crew' ? 'number' : 'text'}
                        value={item[column.replace(/ /g, '')]}
                        onChange={(e) => handleItemChange(listName, item.id, column.replace(/ /g, ''), e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    )}
                  </td>
                ))}
                <td className="px-6 py-4">
                  <button onClick={() => {
                    setFormData(prevState => ({
                      ...prevState,
                      [listName]: prevState[listName].filter(i => i.id !== item.id)
                    }));
                  }} className="font-medium text-red-600 dark:text-red-500 hover:underline">Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const filterUnwantedParams = (listName, items) => {
    const allowedKeys = new Set(columns[listName]);
    return items.map(item => {
      return Object.keys(item)
        .filter(key => allowedKeys.has(key) && key !== 'id')
        .reduce((obj, key) => {
          obj[key] = item[key];
          return obj;
        }, {});
    });
  };

  return (
    <div className="container mx-auto p-4 relative">
      {isDraft && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4 dark:bg-yellow-900 dark:text-yellow-300">
          <p className="font-bold">Draft Loaded</p>
          <p>You are currently viewing a saved draft. Submit the form to finalize or clear the draft to start fresh.</p>
        </div>
      )}
      {submitting && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}
      {showSuccess && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center">
            <svg className="w-16 h-16 text-green-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Success!</h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">Your form has been submitted successfully.</p>
            <button
              onClick={() => {
                setShowSuccess(false);
                navigate(-1);
              }}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded dark:bg-green-600 dark:hover:bg-green-700"
            >
              OK
            </button>
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-sm">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center">
            <svg className="w-16 h-16 text-yellow-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
            </svg>
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Confirm Submission</h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">Are you sure you want to submit this form?</p>
            <div className="flex space-x-4">
              <button
                onClick={handleSubmit}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded dark:bg-green-600 dark:hover:bg-green-700"
              >
                Confirm
              </button>
              <button
                onClick={() => setShowConfirmation(false)}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded dark:bg-red-600 dark:hover:bg-red-700"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <div className={submitting || showSuccess ? 'pointer-events-none' : ''}>
        <button onClick={() => navigate(-1)} className="bg-blue-500 hover:bg-blue-700 text-white mb-1 font-bold py-2 px-4 rounded dark:bg-gray-600 dark:hover:bg-gray-700">Back</button>
        <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">New {type === 'pre' ? 'Pre-Arrival' : 'Post-Departure'} Form</h1>
        <form onSubmit={(e) => { e.preventDefault(); setShowConfirmation(true) }}>
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-2 text-gray-900 dark:text-white">Report Information</h2>
            <div className="grid gap-6 mb-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
              {Object.keys(formData.REPORT_LIST).map((field) => {
                if (field === 'Pre_x002d_Arrival_x002f_Post_x00' || field === 'CallIdLookupId' || field === 'Title') return null;
                const isDisabled = ['Title', 'ShipNameLookupId', 'CruiseLineLookupId', 'Port', 'Previousportofcall', 'TypeofCall', 'Nextportofcall', 'ReportbyLookupId', 'Reportpreparedon_x0028_date_x002', 'ETA_x0028_Pilotstation_x0029_', 'ETD_x0028_Berth_x0029_'].includes(field);
                return (
                  <div key={field}>
                    <label htmlFor={field} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      {labelHeaders[field]}
                    </label>
                    <input
                      type={
                        field.includes('date') || field.includes('ETA') || field.includes('ETD')
                          ? "datetime-local"
                          : ['Pilotonboard', 'Alllinesfast', 'Healthauthorityonboard', 'Customsauthorityonboard', 'Shipcleared', 'Firstcrew_x002f_paxashore', 'Allshipservicescompleted', 'Allpaxcrewonboard', 'Portclearanceissued', 'Shipdeparture'].includes(field)
                            ? "datetime-local"
                            : "text"
                      }
                      id={field}
                      name={field}
                      value={formData.REPORT_LIST[field]}
                      onChange={handleReportChange}
                      onFocus={(e) => {
                        if (e.target.type === "datetime-local") {
                          setTimeout(() => {
                            e.target.showPicker();
                          }, 0);
                        }
                      }}
                      className={`${!formData.REPORT_LIST[field] ? 'border-blue-600 dark:border-blue-500' : ''} border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <hr className="my-6 border-t border-gray-300 dark:border-gray-600" />
          {['DESCRIPTION_LIST', 'DISEMBARKATION_LIST', 'EMBARKATION_LIST', 'PIER_SERVICES_LIST', 'PORT_SERVICES_LIST'].map((listName) => (
            <div key={listName} className="mb-6">
              <h2 className="text-2xl font-semibold mb-2 text-gray-900 dark:text-white">{listName.replace('_LIST', '').replace('_', ' ')}</h2>
              {renderTable(listName)}
              <button
                type="button"
                onClick={() => handleAddItem(listName)}
                className="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                Add Item
              </button>
              <hr className="my-6 border-t border-gray-300 dark:border-gray-600" />
            </div>
          ))}

          <div className="flex space-x-4">
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={saveDraft}
              className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-700 dark:hover:bg-gray-800 dark:focus:ring-gray-900"
            >
              Save Draft
            </button>
            {isDraft && (
              <button
                type="button"
                onClick={() => {
                  const draftKey = `form-draft-${id}-${type}`;
                  localStorage.removeItem(draftKey);
                  setIsDraft(false);
                  window.location.reload();
                }}
                className="text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-700 dark:hover:bg-red-800 dark:focus:ring-red-900"
              >
                Clear Draft
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewFormPage;